import React, { useEffect } from "react";
import { Divider, Button } from "semantic-ui-react";
import styled from "styled-components";
import AdModule from "../components/furniture/AdModule";
import Page from "../components/furniture/Page";
import CategoryTiles from "../components/Home/CategoryTiles";
import ListingCarousel from "../components/Home/ListingCarousel";
import config from "../services/config";
import layout from "../services/layout";
import Head from "next/head";
import adManagerService from "../services/adManagerService";
import AnalyticEventService from "../services/AnalyticEventService";
import searchService from "../backend-services/search/search-service";
import browseService from "../backend-services/browse/browse-service";
import withLogging from "../backend-services/getServerSidePropsWithLogging";
import CategoryLinks from "../components/Home/CategoryLinks";
import VehicleLinks from "../components/Home/VehicleLinks";
import SearchHero from "../components/Home/SearchHero";
import SellerRatingsWidget from "../components/Home/SellerRatingsWidget";

const PageContent = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 40px 10px;
`;

const PageContentPadded = styled(PageContent)`
  padding: 40px 20px;
`;

const AdCallout = styled.div`
  background-color: #1b1b1b;
  text-align: center;
  padding: 20px 0;
  font-size: 110%;
  color: white;
  display: flex;
  justify-content: center;
  gap: 100px;
  align-items: center;

  @media (max-width: ${layout.global.smallMax}) {
    max-width: 100%;
    flex-direction: column;
    gap: 30px;
  }
`;

const AdContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
`;

const AdContainerMobileFriendly = styled(AdContainer)`
  > * {
    margin: 10px;
  }

  @media (max-width: ${layout.global.mediumMax}) {
    display: block;
    padding: 20px 0;
    text-align: center;

    > * {
      margin: 10px auto;
      padding: 1px;
    }
  }
`;

const LightRow = styled.div`
  background-color: #2e2e2e;
`;

const AdCalloutSpan = styled.span`
  @media (max-width: ${layout.global.smallMax}) {
    display: block;
  }
`;

const ads = {
  Web_Home_Leaderboard_1: {
    unitId: "/22057299864/Web_Home_Leaderboard_1",
    elementId: "div-gpt-ad-1636594899161-0",
    sizes: [
      {
        width: 728,
        height: 90,
      },
      {
        width: 320,
        height: 100,
      },
    ],
    height: 100,
    align: "center",
  },
  Web_Home_Small_Rec_1: {
    unitId: "/22057299864/Web_Home_Small_Rec_1",
    elementId: "div-gpt-ad-1636604461781-0",
    width: 320,
    height: 100,
  },
  Web_Home_Small_Rec_2: {
    unitId: "/22057299864/Web_Home_Small_Rec_2",
    elementId: "div-gpt-ad-1636604511880-0",
    width: 320,
    height: 100,
  },
  Web_Home_Small_Rec_3: {
    unitId: "/22057299864/Web_Home_Small_Rec_3",
    elementId: "div-gpt-ad-1636604557104-0",
    width: 320,
    height: 100,
    // static: (new Date().toISOString() <= '2022-05-30T20')
    //   ? {
    //     alt: 'Sparesbox Autofrenzy',
    //     image: '/mock-ads/sparesbox-large-mobile-leaderboard-auto-frenzy.jpg',
    //     url: 'https://sparesbox.com.au/promo/auto-frenzy'
    //   } :
    //   {
    //     alt: 'Sparesbox',
    //     image: '/mock-ads/sparesbox-large-mobile-leaderboard.jpg',
    //     url: 'https://www.sparesbox.com.au/'
    //   }
  },
  Web_Home_Leaderboard_2: {
    unitId: "/22057299864/Web_Home_Leaderboard_2",
    elementId: "div-gpt-ad-1636603787604-0",
    sizes: [
      {
        width: 728,
        height: 90,
      },
      {
        width: 320,
        height: 100,
      },
    ],
    height: 100,
    align: "center",
  },
};

export default function Home({
  featuredAds,
  latestMotorsportVehicles,
  latestPerformanceVehicles,
  latestTrailersAndTransporters,
  latestPartsAccessoriesAndOther,
  categories,
  vehicleHierarchy,
}) {
  useEffect(() => {
    adManagerService.loadAds(ads);
  }, []);

  const canonicalUrl = config.get("WEB_URL");
  const metaTitle = "My105" + layout.global.titleSuffix;
  const metaDescription =
    "Browse motorsport & performance classifieds" + layout.global.titleSuffix;
  const logoImageUrl = "/logo-with-tagline-square-black.png";

  useEffect(() => {
    const mapAd = (ad, index, placement) => {
      return {
        id: ad.id,
        placement: placement,
        position: index + 1,
      };
    };

    (async () => {
      const analyticEvent = {
        eventType: "AD_IMPRESSIONS",
        ads: [
          ...featuredAds.map((ad, index) => mapAd(ad, index, "HOME_FEATURED")),
          ...latestMotorsportVehicles.map((ad, index) =>
            mapAd(ad, index, "HOME_LATEST")
          ),
          ...latestPerformanceVehicles.map((ad, index) =>
            mapAd(ad, index, "HOME_LATEST")
          ),
          ...latestTrailersAndTransporters.map((ad, index) =>
            mapAd(ad, index, "HOME_LATEST")
          ),
          ...latestPartsAccessoriesAndOther.map((ad, index) =>
            mapAd(ad, index, "HOME_LATEST")
          ),
        ],
        metadata: {
          source: "HOMEPAGE",
        },
      };
      await AnalyticEventService.createAnalyticEvent(analyticEvent);
    })();
  }, []);

  return (
    <Page>
      <Head>
        <link rel="canonical" href={canonicalUrl} />
        <title>{metaTitle}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={logoImageUrl} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={canonicalUrl} />
        <meta property="twitter:title" content={metaTitle} />
        <meta property="twitter:description" content={metaDescription} />
        <meta property="twitter:image" content={logoImageUrl} />
      </Head>
      <LightRow>
        <SearchHero />
      </LightRow>
      <AdCallout>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <AdCalloutSpan>
            <strong>Sell on my105</strong>. List until sold.
          </AdCalloutSpan>
          <div>
            <a
              href={
                config.get("SELL_SITE_URL") +
                "?utm_source=homepage_sell_cta&utm_medium=web&utm_campaign=none"
              }
            >
              <Button
                style={{
                  backgroundColor: layout.colors.accent,
                  color: "white",
                }}
                size="large"
              >
                Start selling
              </Button>
            </a>
          </div>
        </div>
        <SellerRatingsWidget />
      </AdCallout>
      <Divider hidden />
      <AdModule config={ads.Web_Home_Leaderboard_1} />
      <Divider hidden />
      {categories && (
        <LightRow>
          <PageContentPadded>
            <CategoryTiles />
            <Divider horizontal inverted>
              <h3>More Categories</h3>
            </Divider>
            <CategoryLinks categories={categories} />
          </PageContentPadded>
        </LightRow>
      )}
      <AdContainerMobileFriendly>
        <AdModule config={ads.Web_Home_Small_Rec_1} />
        <AdModule config={ads.Web_Home_Small_Rec_2} />
        <AdModule config={ads.Web_Home_Small_Rec_3} />
      </AdContainerMobileFriendly>
      <LightRow>
        <PageContent>
          <ListingCarousel
            title="Featured Ads"
            serverAds={featuredAds}
            autoplay
            loop
            pagination
            placement="CAROUSEL_FEATURED_HOMEPAGE"
            isFixedSize
          />
        </PageContent>
      </LightRow>
      <Divider hidden />
      <PageContent>
        <ListingCarousel
          title="Latest Motorsport Vehicles"
          serverAds={latestMotorsportVehicles}
          ctaUrl="/search/motorsport"
          subscribeUrl="motorsport/"
          placement="CAROUSEL_LATEST_CARS"
          adPlacement="HOME_LATEST"
          showActions
          isFixedSize
          queryUrl="/motorsport"
          sortBy="most-recent"
        />
      </PageContent>
      <PageContent>
        <ListingCarousel
          title="Latest Performance Vehicles"
          serverAds={latestPerformanceVehicles}
          ctaUrl="/search/performance"
          subscribeUrl="performance/"
          placement="CAROUSEL_LATEST_PERFORMANCE"
          adPlacement="HOME_LATEST"
          showActions
          isFixedSize
          queryUrl="/performance"
          sortBy="most-recent"
        />
      </PageContent>
      <PageContent>
        <ListingCarousel
          title="Latest Trailers, Transporters &amp; Tow Vehicles"
          serverAds={latestTrailersAndTransporters}
          ctaUrl="/search/trailers-transporters-tow-vehicles"
          subscribeUrl="trailers-transporters-tow-vehicles/"
          placement="CAROUSEL_LATEST_TRAILERS"
          adPlacement="HOME_LATEST"
          showActions
          isFixedSize
          queryUrl="/trailers-transporters-tow-vehicles"
          sortBy="most-recent"
        />
      </PageContent>
      <PageContent>
        <ListingCarousel
          title="Latest Parts &amp; Accessories"
          serverAds={latestPartsAccessoriesAndOther}
          ctaUrl="/search/parts-accessories"
          subscribeUrl="parts-accessories/"
          placement="CAROUSEL_LATEST_PARTS"
          adPlacement="HOME_LATEST"
          showActions
          isFixedSize
          queryUrl="/parts-accessories"
          sortBy="most-recent"
        />
      </PageContent>
      <Divider hidden />
      {vehicleHierarchy && (
        <LightRow>
          <PageContentPadded>
            <VehicleLinks vehicleHierarchy={vehicleHierarchy} />
          </PageContentPadded>
        </LightRow>
      )}
      <Divider hidden />
      <PageContent>
        <ListingCarousel
          title="Latest Auctions"
          serverAds={[]}
          ctaUrl="/search/auction"
          subscribeUrl="auctions/"
          placement="HOME"
          adPlacement="AUCTIONS_ENDING_SOON"
          loadMoreAdPlacement="AUCTIONS_SOLD"
          showActions
          isFixedSize
          popularAdsUrl="/"
          queryUrl="/auction"
          sortBy="ending-soon"
          loadMoreSortBy="sold-auctions"
        />
      </PageContent>
      <Divider inverted />
      <AdModule config={ads.Web_Home_Leaderboard_2} />
      <Divider hidden />
    </Page>
  );
}

export async function getServerSideProps(context) {
  return await withLogging(context, async () => {
    const allCarouselQuery = {
      "include-sold": context.req.cookies.includeSold || "true",
    };
    const latestCarouselQuery = {
      ...allCarouselQuery,
      sort: "most-recent",
      pageSize: 6,
    };

    const [
      featuredAds,
      latestMotorsportVehicles,
      latestPerformanceVehicles,
      latestTrailersAndTransporters,
      latestPartsAccessoriesAndOther,
    ] = await Promise.all([
      searchService.searchByQuery({
        ...allCarouselQuery,
        homepageFeaturedOnly: true,
        sort: "random",
        pageSize: 10,
      }),
      searchService.searchByQuery({
        ...latestCarouselQuery,
        adStream: "MOTORSPORT",
      }),
      searchService.searchByQuery({
        ...latestCarouselQuery,
        adStream: "PERFORMANCE",
      }),
      searchService.searchByQuery({
        ...latestCarouselQuery,
        adStream: "TRAILERS",
      }),
      searchService.searchByQuery({
        ...latestCarouselQuery,
        adStream: "PARTS",
      }),
      browseService.primeVehicleTable(),
    ]);

    // Pass data to the page via props
    return {
      props: {
        featuredAds: featuredAds.results,
        latestMotorsportVehicles: latestMotorsportVehicles.results,
        latestPerformanceVehicles: latestPerformanceVehicles.results,
        latestTrailersAndTransporters: latestTrailersAndTransporters.results,
        latestPartsAccessoriesAndOther: latestPartsAccessoriesAndOther.results,
        categories: browseService.getHomepageCategories(),
        vehicleHierarchy: browseService.getHomepageVehicleHierarchy(),
      },
    };
  });
}
