import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Loader, Modal, Table, Button, Icon } from "semantic-ui-react";
import AdSurveyResponseService from "../../services/AdSurveyResponseService";
import RatingStars from "./RatingStars";

const Container = styled.div`
  text-align: center;
  border-radius: 5px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  height: 63px;
  width: 190px;
`;

const AverageRatingContainer = styled.div`
  text-align: center;
  display: flex;
  gap: 10px;
`;

const ReviewMessage = styled.p`
  margin-top: 10px;
`;

function Review({
  rating,
  message,
  name,
  location,
  dateAgo,
  date,
  adTitle,
  adUrl,
}) {
  return (
    <Table.Row>
      <Table.Cell textAlign="center">
        <RatingStars rating={rating} size="large" />
        <ReviewMessage>{message}</ReviewMessage>
      </Table.Cell>
      <Table.Cell singleLine>
        {name} - {dateAgo}
      </Table.Cell>
      <Table.Cell singleLine>
        {location} - {date}
      </Table.Cell>
      <Table.Cell singleLine>
        <a
          href={adUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "white" }}
        >
          <span style={{ textDecoration: "underline" }}>{adTitle}</span>
          &nbsp;&nbsp;
          <Icon name="external" />
        </a>
      </Table.Cell>
    </Table.Row>
  );
}

function SellerRatingsList({ open, setOpen }) {
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  const getNextPage = useCallback(async () => {
    setIsLoading(true);
    const page = await AdSurveyResponseService.getAdSurveyResponses(
      list.length
    );
    setList([...list, ...page]);
    setIsLoading(false);
  }, [list]);

  useEffect(() => {
    if (open && list.length === 0) {
      getNextPage();
    }
  }, [open, getNextPage, list.length]);

  return (
    <Modal
      basic
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      size="fullscreen"
    >
      <Modal.Content>
        <Button
          basic
          inverted
          floated="right"
          icon="close"
          onClick={() => setOpen(false)}
        />
        <Table inverted basic>
          {list.map((review) => (
            <Review key={review.id} {...review} />
          ))}
        </Table>
        <p style={{ textAlign: "center" }}>
          <Button
            basic
            inverted
            onClick={getNextPage}
            loading={isLoading}
            disabled={isLoading}
            style={{ textAlign: "center" }}
          >
            Load More
            <Icon name="caret right" />
          </Button>
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic inverted onClick={() => setOpen(false)}>
          <Icon name="close" /> Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

function SellerRatingsWidget() {
  const [modalOpen, setModalOpen] = useState(false);
  const [adSurveyResponseSummary, setAdSurveyResponseSummary] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const adSurveyResponseSummary =
          await AdSurveyResponseService.getAdSurveyResponseSummary();
        setAdSurveyResponseSummary(adSurveyResponseSummary);
      } catch (ex) {
        console.error("failed to load seller reviews", ex);
      }
    })();
  }, []);

  return (
    <Container>
      {adSurveyResponseSummary ? (
        <>
          <AverageRatingContainer>
            <div>
              <span style={{ fontSize: "20px", lineHeight: "20px" }}>
                {adSurveyResponseSummary.averageRating.score}
              </span>{" "}
            </div>
            <RatingStars rating={adSurveyResponseSummary.averageRating.score} />
          </AverageRatingContainer>
          <div
            style={{
              fontSize: "12px",
              lineHeight: "12px",
              color: "white",
              textDecoration: "underline",
              color: "#ccc",
              cursor: "pointer",
            }}
            onClick={() => setModalOpen(true)}
          >
            {adSurveyResponseSummary.averageRating.count} reviews in past {adSurveyResponseSummary.averageRating.periodMonths} months
          </div>
        </>
      ) : (
        <Loader active inline="centered">
          Loading reviews...
        </Loader>
      )}
      <SellerRatingsList open={modalOpen} setOpen={setModalOpen} />
    </Container>
  );
}

export default SellerRatingsWidget;
